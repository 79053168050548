const API = {
  MASTER_BASE_URL: "https://master-server.taxgoglobal.com/taxgov2/",
  // BASE_URL: "http://localhost:8082/taxgov2/",
  // BASE_URL: "https://taxgov2-server.taxgoglobal.com/taxgov2/",
  BASE_URL: "https://taxgov2-dev-server.taxgoglobal.com/taxgov2/", // DEV

  TAXGO_V0: "https://main.dd5bgcyjfqt12.amplifyapp.com/login",
  TAXGO_V1: "https://master.dd5bgcyjfqt12.amplifyapp.com/login",


  FILE_PATH: "https://taxgo-v2.s3.eu-west-1.amazonaws.com/",


  LOGIN_REGISTRATION: "auth/register",

  // CREATE_COMPANY: "company_master/createNew",
  CREATE_COMPANY: "company_master/createCompany",

  GET_USER_DETAILS: "user/viewProfile",

  // CREATE_DEFAULT_STAFF: 'contactMaster/defaultStaff/create',
  CREATE_DEFAULT_STAFF: 'contactMaster/defaultStaff/create',

  // ADD_PRODUCT: "ProductMaster/add",
  ADD_PRODUCT: "ProductMaster/add",

  SEND_VERIFY_EMAIL: 'auth/send_verify_mail_retail/',

  VERIFY_EMAIL: "auth/verifyEmail/",

  PRODUCTCATEGORY_LIST_USER: "ProductCategory/user/",

  UNIT_LIST_USER: "unit/list/",

  LOCATION_GET_BY_USER: "location/list/",

  TAX_MASTER: "tax_master/",

  PURCHASE_PRODUCT: 'purchaseinvoice/createRetailPurchase',

  PRODUCTMASTER_IMAGE_UPLOADER: "ProductMaster/updateProductImage",

  UNIT_CREATE: "unit/add",

  PRODUCTCATEGORY_CREATE: "ProductCategory/add",

  LOCATION_POST: "location/add",

  CREATE_TAX_MASTER: "tax_master/add",

  GET_PRODUCT_BY_ID: "ProductMaster/getProductById/",

  // subscribe plan
  GET_PRICING: 'subscriptions/getprice',
  USER_SUBSCRIBED_PLAN: "subscriptions/getUserPlan",
  SUBSCRIPTION_PAYMENT: "stripe_log/subscription",

  USER_SUBSCRIPTION_LIST: "stripe_log/user-log",

  //stripe
  PAYMENT_CREATE: "payment/stripe",
  PAYMENT_RETRIEVE: "payment/retrievePayment",

  //Counter
  POST_COUNTER: 'counter_details/add/openshift',

  // this is paystack api 
  PAYSTACK_PAYMENT: "payment/paystack-initialize",
  WEBURL: "https://www.retailxpress.net/"
  // WEBURL: "http://localhost:3000/"

};
export default API;
